<template>
  <section class="register-finalization">
    <div class="grid-x">
      <div class="cell auto form-container">
        <form @submit.prevent="register">
          <h4 class="title">Bienvenue dans votre espace personnel Maisons Alysia</h4>
          <p class="subtitle">Pour accéder à la personnalisation de votre projet, finalisez votre inscription en renseignant votre mot de passe de connexion.</p>
          <div class="row">
            <app-input
              label="Email"
              v-model="email"
              disabled
              required />
          </div>
          <div class="row">
            <app-input
              label="Prénom"
              v-model="firstname"
              placeholder="Prénom..."
              type="text"
              required
              autocomplete="name"
              :maxlength="128" />
          </div>
          <div class="row">
            <app-input
              label="Nom"
              v-model="lastname"
              placeholder="Nom..."
              type="text"
              required
              autocomplete="family-name"
              :maxlength="128" />
          </div>
          <div class="row">
            <app-input
              label="Numéro de téléphone"
              v-model="phone"
              placeholder="0XXXXXXXXX..."
              type="phone"
              :hasSucceed="isPhoneFormInvalid ? 'error' : null"
              :msg="isPhoneFormInvalid ? errorMessage : null"
              required
              autocomplete="tel-local"
              :maxlength="10"
              :minlength="10" />
          </div>
          <div class="row">
            <app-input
              label="Mot de passe"
              v-model="password"
              placeholder="Mot de passe..."
              type="password"
              :minlength="8"
              autocomplete="new-password"
              required />
          </div>
          <div class="row">
            <app-button type="submit" :disabled="areFieldsEmpty">Enregistrer</app-button>
          </div>
        </form>
      </div>
      <div class="cell auto presentation">
        <div class="blur"></div>
        <div class="content">
          <logo-bird class="logo"/>
          <h6>Le plus court chemin entre vous et votre maison</h6>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import logoBird from '@/assets/logos/bird.svg?inline';

export default {
  components: {
    'logo-bird': logoBird,
  },
  data() {
    return {
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      password: '',
      errorMessage: '',
      agencyError: false,
      isMailFormInvalid: false,
      isPhoneFormInvalid: false,
      isOpen: false,
    };
  },
  computed: {
    areFieldsEmpty() {
      return !(this.email && this.firstname && this.lastname && this.phone && this.password);
    },
  },
  mounted() {
    this.email = decodeURIComponent(this.$route.query.email);
    this.firstname = this.$route.query.firstname === 'unknown' ? '' : decodeURIComponent(this.$route.query.firstname);
    this.lastname = this.$route.query.lastname === 'unknown' ? '' : decodeURIComponent(this.$route.query.lastname);
    this.phone = this.$route.query.phone === '0000000000' ? '' : decodeURIComponent(this.$route.query.phone);
  },
  methods: {
    async register() {
      this.errorMessage = '';
      try {
        await authApi.registerFinalization(this.$route.query.token, this.password, this.firstname, this.lastname, this.phone);
        this.isMailFormInvalid = false;
        this.isPhoneFormInvalid = false;
        // On log automatiquement après l'inscription
        await authApi.login(this.email, this.password);

        this.$analyticsModal.show({
          title: 'Merci !',
          text: 'Votre compte a bien été créé',
          hasConfirm: true,
          hasCancel: true,
          confirmText: 'Voir mon compte',
          cancelText: 'Retour au site',
          queryName: 'remerciement-inscription',
          onConfirm: async () => {
            await this.$router.push({ name: 'account', params: { tab: 'mes-projets' } }).catch(() => true);
          },
          onCancel: async () => {
            await this.$router.push({ name: 'home' }).catch(() => true);
          },
        });
      } catch (e) {
        switch (e.response.status) {
          // Cas bad Request
          case 400: {
            if (e.response.data.message === 'Email already used') {
              this.isMailFormInvalid = true;
              this.errorMessage = 'Cette adresse est déjà utilisée sur un compte';
            } if (e.response.data.message[0] === 'Not a valid phone number') {
              this.isPhoneFormInvalid = true;
              this.errorMessage = 'Le format du numéro de téléphone n\'est pas correct';
            } else {
              this.isMailFormInvalid = true;
              this.isPhoneFormInvalid = true;
              this.errorMessage = 'Une erreur est survenue';
            }
            break;
          }
          // Cas Forbidden
          case 403: {
            this.$message.show({
              title: 'Erreur',
              text: 'Ce lien d\'inscription a déjà été utilisé',
              confirmText: 'Se connecter',
              onConfirm() {
                this.$router.push({ name: 'login' });
              },
              hasConfirm: true,
            });
            break;
          }
          // Cas Not Found
          case 404: {
            this.$message.show({
              title: 'Erreur',
              text: 'Ce lien d\'inscription est invalide',
              confirmText: 'Retour à l\'accueil',
              onConfirm() {
                this.$router.push({ name: 'home' });
              },
              hasConfirm: true,
            });
            break;
          }
          default: {
            this.isMailFormInvalid = true;
            this.isPhoneFormInvalid = true;
            this.errorMessage = 'Une erreur est survenue';
          }
        }
      }
    },
  },
};

</script>

<style lang="sass" scoped>
  .register-finalization
    margin-top: 70px
    > .grid-x
      min-height: 80vh
    .cell
      padding: 40px
    .form-container
      background-color: $medium-bg
    .title
      color: black
      font-size: 20px
      font-weight: 500
      margin: 0
    .subtitle
      margin: 1rem 0 2rem 0
      color: $subtitle
    .row
      @include row
      width: 100%
    label > span
      @include label
    form
      display: flex
      flex-direction: column
      justify-content: center
      height: 100%
      max-width: 400px
      margin: auto
      .row .app-button
        width: 100%
    .presentation
      background-image: url(../../assets/img/pages/background-house.jpeg)
      background-size: cover
      background-position: center
      position: relative
      @media (max-width: 1080px)
        display: none
      .blur
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        z-index: 1
        @include black-filter
      .content
        height: 100%
        z-index: 2
        position: relative
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .logo
          margin-bottom: 20px
      h6
        color: $white
      .back-link
        margin-top: 30px
        margin-bottom: 50px
        text-decoration: none
</style>
